@font-face {
  font-family: GothamPro;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/GothamProLight.woff') format('woff');
}
@font-face {
  font-family: GothamPro;
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/GothamProLightItalic.woff') format('woff');
}

@font-face {
  font-family: GothamPro;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GothamPro.woff') format('woff');
}
@font-face {
  font-family: GothamPro;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/GothamProItalic.woff') format('woff');
}

@font-face {
  font-family: GothamPro;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/GothamProMedium.woff') format('woff');
}
@font-face {
  font-family: GothamPro;
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/GothamProMediumItalic.woff') format('woff');
}

@font-face {
  font-family: GothamPro;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/GothamProBold.woff') format('woff');
}
@font-face {
  font-family: GothamPro;
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/GothamProBoldItalic.woff') format('woff');
}

@font-face {
  font-family: GothamPro;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/GothamProBlack.woff') format('woff');
}
@font-face {
  font-family: GothamPro;
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/GothamProBlackItalic.woff') format('woff');
}
